import React from 'react';
import styled, {css, keyframes} from "styled-components";
import {PageLayoutContent} from "../../components/PageLayout";
import useInitPropChanged from "../../hooks/useInitPropChanged";
import {DisplayProps} from "./index";

const appearKeyframes = keyframes`
  from { opacity: 0; filter: blur(16px); }
  to { opacity: 1; filter: blur(0); }
`;

const disappearKeyframes = keyframes`
  from { opacity: 1; filter: blur(0); }
  to { opacity: 0; filter: blur(16px); }
`;

const S = {
  Container: styled(PageLayoutContent)<DisplayProps>`
    opacity: 0;
    position: absolute;
    inset: 0;
    animation-duration: .25s;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-name: ${appearKeyframes};
    animation-delay: .25s;
    gap: 8px;

    ${({$hidden, $initHidden}) => $hidden && css`
      ${() => $initHidden && css`animation-duration: 0s;`}
      animation-name: ${disappearKeyframes};
      animation-delay: 0s;
      pointer-events: none;
    `}
  `,
  EventName: styled.div`
    font-size: 40px;
  `,
  StartsIn: styled.div`
    font-size: 24px;
  `,
  Countdown: styled.div`
    font-size: 40px;
  `,
}

type StartingProps = DisplayProps & {
  eventName?: string,
  startingCountdown: number;
}

function Starting({ eventName, startingCountdown, $hidden}: StartingProps) {
  const [initHidden, initChanged] = useInitPropChanged($hidden);

  if (!eventName) {
    return <></>;
  }

  return (
    <S.Container $hidden={$hidden} $initHidden={initHidden && !initChanged}>
      <S.EventName>{eventName}</S.EventName>
      <S.StartsIn>starts in</S.StartsIn>
      <S.Countdown>{Math.max(0, (startingCountdown - 1000) / 1000).toFixed(0)}s</S.Countdown>
    </S.Container>
  );
}

export default Starting;
