import React, {useCallback, useEffect, useState} from 'react';
import styled from "styled-components";
import {OnResultFunction, QrReader} from 'react-qr-reader';
import TypingTextAnimation from "../components/TypingTextAnimation";
import _ from "lodash";
import {PageLayoutContent} from "../components/PageLayout";
import useApp from "../hooks/useApp";

const S = {
  Header: styled.h1`
    margin: 16px 0;
    font-size: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
  `,
  QrReaderLabel: styled.h3`
    height: 23px;
    margin: 0 0 16px;
  `,
  QrReaderContainer: styled.div`
    height: 300px;
    width: 300px;
  `,
  Footer: styled.footer`
    margin-bottom: 24px;
    position: absolute;
    bottom: 0;
  `
}

const appHostName = (process.env.REACT_APP_ANEHTA_APP_URL || '').replaceAll('https://', '').replace('http://', '');

const ANEHTA_TEXTS = ['ANEHTA', 'ATHENA'];
const SCAN_QR_CODE_TEXTS = ['Scan QR code to challenge'];

function Home() {
  const {resetTheme} = useApp();
  const [hasCamera, setHasCamera] = useState<boolean>(false);
  const onQrScanned = useCallback<OnResultFunction>((result, error, browserQrCodeReader) => {
    setHasCamera(!!browserQrCodeReader);
    if (error) {
      return;
    }
    const resultText = result?.getText();
    if (_.isString(resultText)) {
      const resultHostname = resultText.replaceAll('https://', '').replace('http://', '');
      if (resultHostname.startsWith(appHostName)) {
        window.location.href = resultText;
      } else {
        window.location.href = `${process.env.REACT_APP_ANEHTA_APP_URL}/event/${resultText}`;
      }
    } else {
      alert('Invalid code');
    }
  }, []);

  useEffect(() => {
    resetTheme();
  }, [resetTheme]);

  return (
    <>
      <PageLayoutContent>
        <S.Header>
          <TypingTextAnimation strings={ANEHTA_TEXTS} replay replayTimeout={3000} timeout={30} iterations={5}/>
        </S.Header>
        {appHostName && (
          <>
            {hasCamera && (
              <S.QrReaderLabel>
                <TypingTextAnimation strings={SCAN_QR_CODE_TEXTS} replay replayTimeout={3000} timeout={10}
                                     iterations={4}/>
              </S.QrReaderLabel>
            )}
            <S.QrReaderContainer>
              <QrReader
                onResult={onQrScanned}
                constraints={{facingMode: {ideal: 'environment'}, width: 300, height: 300}}
                containerStyle={{width: 300, height: 300}}
              />
            </S.QrReaderContainer>
          </>
        )}
      </PageLayoutContent>
      <S.Footer>from <i>nuegyn</i> with ❤️</S.Footer>
    </>
  )
    ;
}

export default Home;
