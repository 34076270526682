import {useCallback, useEffect, useMemo, useState} from "react";
import _ from "lodash";
import {v4 as uuidv4} from 'uuid';
import useApp from "../../hooks/useApp";

export type Quiz = {
  id: string,
  uiId?: string,
  extraTime?: number,
  question: string,
  correctAnswer: string,
  wrongAnswer: string,
  image?: { url: string, },
  timeout: number,
};

export type Reward = {
  id: string,
  name: string,
  congratsMessage: string,
  requiredPoints: number,
  image: { url: string }
};

export type EventQuiz = {
  id: string,
  name: string,
  welcomeMessage: string,
  profile?: {
    logoImage?: { url: string},
    primaryColor: string,
    accentColor: string,
    facebookUrl?: string,
    instagramUrl?: string,
    linkedinUrl?: string,
    contactUrlsJson?: string,
  }
  collectInfoJson?: string,
  image?: { url: string, }
  quizSet: {
    quizzes: Quiz[],
    luckyStarQuizzes: Quiz[],
  }
  numberOfQuizzes: number
  numberOfLuckyStarQuizzes: number,
  rewards: Reward[],
  noRewardMessage: string,
  thankYouMessage: string,
};

export type EventQuizState = 'NO_EVENT' | 'IDLE' | 'STARTING' | 'PLAYING' | 'LUCKY_STAR_CONFIRMING' | 'FINISHED';

export type EventQuizProgress = {
  current: number,
  total: number,
  isLuckyStar: boolean | undefined,
  score: number,
  streak: number,
  maxStreak: number
};

type EventQuizHook = {
  setEvent: (event: EventQuiz | undefined) => void,
  state: EventQuizState,
  quizzes: Quiz[],
  luckyStarQuizzes: Quiz[],
  start: () => void,
  quiz?: Quiz,
  startingCountdown: number,
  progress: EventQuizProgress,
  answer: (answerOption: boolean) => void,
  acceptLuckyStar: (ok: boolean) => void,
  nextQuizDelay: number,
};

const EVENT_QUIZ_STARTING_TIMEOUT = 4000;
const EVENT_QUIZ_STARTING_INTERVAL = 1000;
const EVENT_QUIZ_TOTAL_LUCKY_STAR_POINT_MULTIPLE = 1;
const NEXT_QUIZ_DELAY = 1500;
const CONSUMING_TIME_PCT_POINT: {[key: number]: number} = {
  0: 10,
  10: 9,
  20: 8,
  30: 7,
  40: 6,
  50: 5,
  60: 4,
  70: 3,
  80: 2,
  90: 1,
  100: 0,
};
const MAX_STREAK = 5;
const STREAK_BONUSES: {[key: number]: number} = {
  0: 0,
  1: .2,
  2: .4,
  3: .6,
  4: .8,
  5: 1,
};

const generateQuizzes = (quizzes: Quiz[], numberOfQuizzes: number) => {
  const shuffledQuizzes = _.shuffle(quizzes);
  const newQuizzes = [];
  const quotient = Math.floor(numberOfQuizzes / shuffledQuizzes.length);
  const remainder = numberOfQuizzes % shuffledQuizzes.length;
  _.times(quotient, () => newQuizzes.push(..._.slice(shuffledQuizzes)));

  if (remainder) {
    newQuizzes.push(..._.slice(shuffledQuizzes, 0, remainder));
  }
  return _.map(newQuizzes, (quiz, i) => ({
    ..._.cloneDeep(quiz),
    extraTime: i === 0 ? 250 : 0,
    timeout: i === 0 ? quiz.timeout + 250 : quiz.timeout, // extra time for first question due to animation
    uiId: uuidv4(),
  }));
}

export const useEventQuiz = (): EventQuizHook => {
  const [event, setEvent] = useState<EventQuiz>();
  const [state, setState] = useState<EventQuizState>('NO_EVENT');
  const [quiz, setQuiz] = useState<Quiz>();
  const [startingCountdown, setStartingCountdown] = useState(EVENT_QUIZ_STARTING_TIMEOUT);
  const [quizzes, setQuizzes] = useState<Quiz[]>([]);
  const [luckyStarQuizzes, setLuckyStarQuizzes] = useState<Quiz[]>([]);
  const [score, setScore] = useState<number>(0);
  const [isLuckyStar, setLuckyStar] = useState<boolean>();
  const [answeringTimeoutId, setAnsweringTimeoutId] = useState<ReturnType<typeof setTimeout>>();
  const [answeringStartTime, setAnsweringStartTime] = useState<number>();
  const [streak, setStreak] = useState<number>(0);
  const {ga} = useApp();

  const start = useCallback(() => {
    if (state !== 'IDLE') {
      throw Error('Event Quiz are not in idle state');
    }
    setState('STARTING');
  }, [state]);

  const reset = useCallback(() => {
    setState(event ? 'IDLE' : 'NO_EVENT');
    setStartingCountdown(EVENT_QUIZ_STARTING_TIMEOUT);
    setQuiz(undefined);
    setScore(0);
    setLuckyStar(undefined);

    const quizzes = event?.quizSet?.quizzes;
    const numberOfQuizzes = event?.numberOfQuizzes || 0;
    if (_.isArray(quizzes) && numberOfQuizzes > 0) {
      setQuizzes(generateQuizzes(quizzes, numberOfQuizzes));
    } else {
      setQuizzes([]);
    }

    const luckyStarQuizzes = event?.quizSet?.luckyStarQuizzes;
    const numberOfLuckyStarQuizzes = event?.numberOfLuckyStarQuizzes || 0;
    if (_.isArray(luckyStarQuizzes) && numberOfLuckyStarQuizzes > 0) {
      setLuckyStarQuizzes(generateQuizzes(luckyStarQuizzes, numberOfLuckyStarQuizzes));
    } else {
      setLuckyStarQuizzes([]);
    }
  }, [event]);

  const nextQuiz = useCallback(() => {
    const currentQuizzes = isLuckyStar === true ? luckyStarQuizzes : quizzes;
    const currentQuizIndex = _.indexOf(currentQuizzes, quiz);

    if (state !== 'PLAYING') {
      return;
    }

    const timeout = currentQuizIndex === -1 ? 0 : NEXT_QUIZ_DELAY;

    setTimeout(() => {
      if (currentQuizIndex === currentQuizzes.length - 1) {
        if (luckyStarQuizzes.length > 0 && isLuckyStar === undefined) {
          setState('LUCKY_STAR_CONFIRMING');
        } else {
          setState('FINISHED');
        }
        return;
      }

      if (currentQuizIndex === -1) {
        setQuiz(currentQuizzes[0]);
      } else {
        setQuiz(currentQuizzes[currentQuizIndex + 1]);
      }
    }, timeout);
  }, [isLuckyStar, luckyStarQuizzes, quiz, quizzes, state]);

  useEffect(() => {
    reset();
  }, [reset]);

  useEffect(() => {
    if (state === 'LUCKY_STAR_CONFIRMING' && score <= 0) {
      setState('FINISHED');
    }
  }, [state, score]);

  useEffect(() => {
    let interval: ReturnType<typeof setInterval>;
    if (state === 'STARTING') {
      interval = setInterval(() => {
        const remaining = startingCountdown - EVENT_QUIZ_STARTING_INTERVAL;
        if (remaining <= 0) {
          clearInterval(interval);
          setStartingCountdown(0);
          setState('PLAYING');
          nextQuiz();
        } else {
          setStartingCountdown(remaining);
        }
      }, EVENT_QUIZ_STARTING_INTERVAL);
    }

    return () => {
      clearInterval(interval);
    }
  }, [state, nextQuiz, startingCountdown]);

  const progress = useMemo(() => ({
    current: (isLuckyStar ? quizzes.length + _.indexOf(luckyStarQuizzes, quiz) : _.indexOf(quizzes, quiz)) + 1,
    total: quizzes.length + (isLuckyStar ? luckyStarQuizzes.length : 0),
    isLuckyStar,
    score,
    streak,
    maxStreak: MAX_STREAK,
  }), [isLuckyStar, luckyStarQuizzes, quiz, quizzes, score, streak]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      nextQuiz();
      if (event?.id) {
        ga.event({
          category: event.id,
          action: 'Quiz Timeout',
          label: `Quiz ${quiz?.id}`,
        });
      }
    }, quiz?.timeout);
    setAnsweringTimeoutId(timeoutId);
    setAnsweringStartTime(Date.now());

    return () => {
      clearTimeout(timeoutId);
    }
  }, [quiz, nextQuiz, event?.id, ga]);

  const answer = useCallback((answerOption: boolean) => {
    if (!quiz) {
      throw Error("Answer to what!?!?!?!!");
    }

    if (!answeringStartTime) {
      throw Error("Cheating!?!?!?!!");
    }

    clearTimeout(answeringTimeoutId);
    const consumingTime = Date.now() - answeringStartTime;
    const consumingTimePct = Math.floor((consumingTime / quiz.timeout) * 10) * 10;

    let gainedPoint;
    if (isLuckyStar) {
      gainedPoint = Math.round(score * EVENT_QUIZ_TOTAL_LUCKY_STAR_POINT_MULTIPLE / luckyStarQuizzes.length);
    } else {
      const point = CONSUMING_TIME_PCT_POINT[consumingTimePct] || 0;
      const streakBonus = point * STREAK_BONUSES[streak];
      gainedPoint = point + streakBonus;
    }

    if (answerOption) {
      const newStreak = Math.min(streak + 1, MAX_STREAK);
      setStreak(newStreak);
      setScore(score + gainedPoint);
      if (event?.id) {
        ga.event({
          category: event.id,
          action: 'Correct answer',
          label: `Quiz ${quiz.id}`,
          value: consumingTimePct,
        });
      }
    } else {
      setStreak(0);
      if (isLuckyStar) {
        setScore(score - gainedPoint);
      }
      if (event?.id) {
        ga.event({
          category: event.id,
          action: 'Wrong answer',
          label: `Quiz ${quiz.id}`,
          value: consumingTimePct,
        });
      }
    }
    nextQuiz();
  }, [quiz, score, nextQuiz, isLuckyStar, luckyStarQuizzes?.length, answeringTimeoutId, answeringStartTime, streak, event?.id, ga]);

  const acceptLuckyStar = useCallback((ok: boolean) => {
    if (state !== 'LUCKY_STAR_CONFIRMING') {
      throw Error("Eh? Not the time for you to accept the Lucky Star");
    }

    setLuckyStar(ok);
    if (ok) {
      setState('PLAYING');
      setQuiz(luckyStarQuizzes[0]);

      if (event?.id) {
        ga.event({
          category: event.id,
          action: 'Accept Lucky Star quiz'
        });
      }

    } else {
      setState('FINISHED');

      if (event?.id) {
        ga.event({
          category: event.id,
          action: 'Reject Lucky Star quiz'
        });
      }
    }
  }, [state, luckyStarQuizzes, event?.id, ga]);

  useEffect(() => {
    if (!event?.id) {
      return;
    }
    switch (state) {
      case "IDLE":
        ga.event({
          category: event.id,
          action: 'Open event'
        });
        break;
      case "STARTING":
        ga.event({
          category: event.id,
          action: 'Start quiz'
        });
        break;
      case "FINISHED":
        ga.event({
          category: event.id,
          action: 'Finished quiz',
          value: score,
        });
        break;
    }
  }, [state, score, event?.id, ga]);

  const isLastQuiz = _.indexOf(luckyStarQuizzes, quiz) === luckyStarQuizzes.length - 1 || _.indexOf(quizzes, quiz) === quizzes.length - 1;

  return {
    setEvent,
    state,
    start,
    quiz,
    quizzes,
    luckyStarQuizzes,
    startingCountdown,
    progress,
    answer,
    acceptLuckyStar,
    nextQuizDelay: isLastQuiz ? 0 : NEXT_QUIZ_DELAY,
  }
}
