import styled from "styled-components";

const Panel = styled.div`
  background: ${props => props.theme.accentColor};
  color: ${props => props.theme.primaryColor};
  border-radius: 16px;
  padding: 24px;

  @media (max-height: 700px) {
    padding: 16px;
  }
`

export default Panel;
