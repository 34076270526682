import {useEffect, useState} from "react";

const useInitPropChanged = <T>(prop: T) => {
  const [initChanged, setInitChanged] = useState<boolean>(false);
  const [initProp] = useState<T>(prop);

  useEffect(() => {
    if (initProp !== prop) {
      setInitChanged(true);
    }
  }, [initProp, prop]);

  return [initProp, initChanged];
}

export default useInitPropChanged;
