import React from 'react';
import styled, {keyframes} from "styled-components";

const flyingKeyframes1 = keyframes`
  0% {
    top: 100%;
    left: 10%;
  }
  10% {
    opacity: 1;
  }
  25% {
    top: 75%;
    left: 25%;
  }
  75% {
    top: 25%;
    left: 5%;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    top: 0;
    left: 10%;
  }
`;

const flyingKeyframes2 = keyframes`
  0% {
    top: 100%;
    left: 17.5%;
  }
  10% {
    opacity: 1;
  }
  25% {
    top: 75%;
    left: 7.5%;
  }
  75% {
    top: 25%;
    left: 20%;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    top: 0;
    left: 37.5%;
  }
`;

const flyingKeyframes3 = keyframes`
  0% {
    top: 100%;
    left: 50%;
  }
  10% {
    opacity: 1;
  }
  25% {
    top: 75%;
    left: 70%;
  }
  75% {
    top: 25%;
    left: 60%;
  }
  85% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    top: 0;
    left: 80%;
  }
`;

const flyingKeyframes4 = keyframes`
  0% {
    top: 100%;
    left: 65%;
  }
  10% {
    opacity: 1;
  }
  25% {
    top: 75%;
    left: 75%;
  }
  75% {
    top: 25%;
    left: 65%;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    top: 0;
    left: 75%;
  }
`;

const flyingKeyframes5 = keyframes`
  0% {
    top: 100%;
    left: 50%;
  }
  25% {
    opacity: 1;
    top: 75%;
    left: 45%;
  }
  75% {
    top: 25%;
    left: 55%;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    top: 0;
    left: 75%;
  }
`;

export type EmojiUiType = {
  duration?: number,
}

export const Emoji = styled.div`
  opacity: 0;
  font-size: 48px;
  position: absolute;
`;

const S = {
  Container: styled.div`
    overflow: visible;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
  `,
  Emoji1: styled(Emoji)<EmojiUiType>`
    animation: ${({duration}) => duration || 3}s ${flyingKeyframes1} linear infinite;
    animation-delay: 0.25s;
  `,
  Emoji2: styled(Emoji)<EmojiUiType>`
    animation: ${({duration}) => duration || 3}s ${flyingKeyframes2} linear infinite;
    animation-delay: 1.5s;
  `,
  Emoji3: styled(Emoji)<EmojiUiType>`
    animation: ${({duration}) => duration || 3}s ${flyingKeyframes3} linear infinite;
    animation-delay: 1s;
  `,
  Emoji4: styled(Emoji)<EmojiUiType>`
    animation: ${({duration}) => duration || 3}s ${flyingKeyframes4} linear infinite;
    animation-delay: 0s;
  `,
  Emoji5: styled(Emoji)<EmojiUiType>`
    animation: ${({duration}) => duration || 3}s ${flyingKeyframes5} linear infinite;
    animation-delay: 0.75s;
  `,
}

type FlyingEmojiProps = {
  emoji: string;
  duration?: number;
  className?: string;
}

function FlyingEmoji({emoji, duration, className}: FlyingEmojiProps) {

  return (
    <S.Container className={className}>
      <S.Emoji1 duration={duration}>{emoji}</S.Emoji1>
      <S.Emoji2 duration={duration}>{emoji}</S.Emoji2>
      <S.Emoji3 duration={duration}>{emoji}</S.Emoji3>
      <S.Emoji4 duration={duration}>{emoji}</S.Emoji4>
      <S.Emoji5 duration={duration}>{emoji}</S.Emoji5>
    </S.Container>
  );
}

export default FlyingEmoji;
