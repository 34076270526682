import styled from "styled-components";
import {ReactNode} from "react";

const S = {
  Container: styled.div`
    position: absolute;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
    padding: 24px 24px 40px;
    box-sizing: border-box;
    background-color: ${props => props.theme.primaryColor};
    color: ${props => props.theme.accentColor};
    transition: background-color 0.5s;

    @media (max-height: 700px) {
      padding: 16px 16px 32px;
    }
  `,
  Content: styled.div`
    width: 100%;
    max-width: 500px;
    height: 100%;
    max-height: 1000px; 
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    text-align: center;
    overflow: visible;
  `,
  ScrollingContent: styled.div`
    position: absolute;
    inset: 0;
    text-align: center;
    padding: 24px;
    box-sizing: border-box;
    overflow: auto;

    @media (max-height: 700px) {
      padding: 16px;
    }
  `,
};

type ScrollableContentProps = {
  children: ReactNode,
  className?: string,
}

const ScrollableContent = ({children, className}: ScrollableContentProps) => {
  return (
    <S.Content className={className}>
      <S.ScrollingContent>
        {children}
      </S.ScrollingContent>
    </S.Content>
  );
}

type PageLayoutProps = {
  children: ReactNode,
  className?: string,
}

const PageLayout = ({children, className}: PageLayoutProps) => {
  return (
    <S.Container className={className}>
      <S.Content>
        {children}
      </S.Content>
    </S.Container>
  )
}

export default PageLayout;
export const PageLayoutContent = S.Content;
export const PageLayoutScrollableContent = ScrollableContent;
export const PageLayoutScrollingContent = S.ScrollingContent;
