import _ from "lodash";
// @ts-ignore
import imageToBase64 from 'image-to-base64/browser';


const base64ImgPool: { [imgUrl: string]: string } = {};

export function resolveImageUrl(imgUrl?: string): string {
  if (!imgUrl) {
    return '';
  }
  const fullPathImgUrl = process.env.REACT_APP_ANEHTA_CMS_IMAGE_BASE_URL + imgUrl;
  if (base64ImgPool[fullPathImgUrl]) {
    return base64ImgPool[fullPathImgUrl];
  }
  return fullPathImgUrl;
}

async function promiseAllInBatches(task: Function, items: any[], batchSize: number, onProgress?: (progress: number) => void) {
  let position = 0;
  let progress = 0;
  while (position < items.length) {
    const itemsForBatch = items.slice(position, position + batchSize);
    // eslint-disable-next-line
    await Promise.all(itemsForBatch.map(async item => {
      await task(item);
      progress++;
      onProgress?.(progress/items.length);
    }))
    position += batchSize;
  }
}

async function preloadImage(imgUrl: string) {
  try {
    const base64Img = await imageToBase64(imgUrl);
    base64ImgPool[imgUrl] = `data:image/jpeg;charset=utf-8;base64,${base64Img}`;
  } catch (err) {
    console.warn(`Cannot load img: ${imgUrl}`, err);
  }
}

export function preloadImages(imgUrls: string[], onProgress?: (progress: number) => void): Promise<void> {
  const validImgUrls = _.filter(imgUrls, imgUrl => !!imgUrl);

  return new Promise<void>(async (resolve) => {
    await promiseAllInBatches(preloadImage, validImgUrls, 10, onProgress);
    resolve();
  });
}

export function shadeColor(color: string, percent: number) {
  let R = parseInt(color.substring(1, 3), 16);
  let G = parseInt(color.substring(3, 5), 16);
  let B = parseInt(color.substring(5, 7), 16);

  R = R * (100 + percent) / 100;
  G = G * (100 + percent) / 100;
  B = B * (100 + percent) / 100;

  R = (R < 255) ? R : 255;
  G = (G < 255) ? G : 255;
  B = (B < 255) ? B : 255;

  R = Math.round(R)
  G = Math.round(G)
  B = Math.round(B)

  let RR = ((R.toString(16).length === 1) ? "0" + R.toString(16) : R.toString(16));
  let GG = ((G.toString(16).length === 1) ? "0" + G.toString(16) : G.toString(16));
  let BB = ((B.toString(16).length === 1) ? "0" + B.toString(16) : B.toString(16));

  return `#${RR}${GG}${BB}`;
}
