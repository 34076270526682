import React from 'react';
import styled, {keyframes} from "styled-components";

const rippleKeyframes = keyframes`
  0% {
    background-color: rgba(0, 0, 0, 0.1);
  }
  30% {
    background-color: var(--cell-color);
  }
  60% {
    background-color: rgba(0, 0, 0, 0.1);
  }
  100% {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const S = {
  Container: styled.div`
    .mosaic-loader {
      --cell-size: 32px;
      --cell-spacing: 1px;
      --border-width: 1px;
      --cells: 4;
      --total-size: calc(var(--cells) * (var(--cell-size) + 2 * var(--cell-spacing)));
      display: flex;
      flex-wrap: wrap;
      width: var(--total-size);
      height: var(--total-size);
    }

    .mosaic-loader > .cell {
      --cell-color: white;
      flex: 0 0 var(--cell-size);
      margin: var(--cell-spacing);
      background-color: rgba(0, 0, 0, 0.1);
      box-sizing: border-box;
      border: none;
      animation: 1.5s ${rippleKeyframes} ease infinite;
    }

    .mosaic-loader > .cell.d-1 {
      animation-delay: 100ms;
    }

    .mosaic-loader > .cell.d-2 {
      animation-delay: 200ms;
    }

    .mosaic-loader > .cell.d-3 {
      animation-delay: 300ms;
    }

    .mosaic-loader > .cell.d-4 {
      animation-delay: 400ms;
    }

    .mosaic-loader > .cell.d-5 {
      animation-delay: 500ms;
    }

    .mosaic-loader > .cell.d-6 {
      animation-delay: 600ms;
    }

    .mosaic-loader > .cell:nth-child(1) {
      --cell-color: #d4aee0;
    }

    .mosaic-loader > .cell:nth-child(2) {
      --cell-color: #8975b4;
    }

    .mosaic-loader > .cell:nth-child(3) {
      --cell-color: #64518a;
    }

    .mosaic-loader > .cell:nth-child(4) {
      --cell-color: #565190;
    }

    .mosaic-loader > .cell:nth-child(5) {
      --cell-color: #44abac;
    }

    .mosaic-loader > .cell:nth-child(6) {
      --cell-color: #2ca7d8;
    }

    .mosaic-loader > .cell:nth-child(7) {
      --cell-color: #1482ce;
    }

    .mosaic-loader > .cell:nth-child(8) {
      --cell-color: #05597c;
    }

    .mosaic-loader > .cell:nth-child(9) {
      --cell-color: #b2dd57;
    }

    .mosaic-loader > .cell:nth-child(10) {
      --cell-color: #57c443;
    }

    .mosaic-loader > .cell:nth-child(11) {
      --cell-color: #05b853;
    }

    .mosaic-loader > .cell:nth-child(12) {
      --cell-color: #19962e;
    }

    .mosaic-loader > .cell:nth-child(13) {
      --cell-color: #fdc82e;
    }

    .mosaic-loader > .cell:nth-child(14) {
      --cell-color: #fd9c2e;
    }

    .mosaic-loader > .cell:nth-child(15) {
      --cell-color: #d5385a;
    }

    .mosaic-loader > .cell:nth-child(16) {
      --cell-color: #911750;
    }
  `,
}

type LoadingIconProps = {
  className?: string,
}

function LoadingIcon({className}: LoadingIconProps) {

  return (
    <S.Container className={className}>
      <div className="mosaic-loader">
        <div className="cell d-0"></div>
        <div className="cell d-1"></div>
        <div className="cell d-2"></div>
        <div className="cell d-3"></div>
        <div className="cell d-1"></div>
        <div className="cell d-2"></div>
        <div className="cell d-3"></div>
        <div className="cell d-4"></div>
        <div className="cell d-2"></div>
        <div className="cell d-3"></div>
        <div className="cell d-4"></div>
        <div className="cell d-5"></div>
        <div className="cell d-3"></div>
        <div className="cell d-4"></div>
        <div className="cell d-5"></div>
        <div className="cell d-6"></div>
      </div>
    </S.Container>
  );
}

export default LoadingIcon;
