import React, {useCallback, useMemo, useState} from 'react';
import styled, {css, keyframes} from "styled-components";
import Button from "../../components/Button";
import {DisplayProps} from "./index";
import _ from "lodash";
import useInitPropChanged from "../../hooks/useInitPropChanged";
import {gql, useMutation} from "@apollo/client";

const appearKeyframes = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const disappearKeyframes = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const CREATE_COLLECTED_INFO_MUTATION = gql`
    mutation CreateCollectedInfo($data: CollectedInfoCreateInput!) {
        createCollectedInfo(data: $data) {
            id
        }
    }
`;

const S = {
  Title: styled.div`
    font-size: 32px;
    margin-bottom: 32px;

    @media (max-height: 700px) {
      font-size: 28px;
      margin-bottom: 28px;
    }
  `,
  StartButton: styled(Button)<{ $disabled: boolean }>`
    margin-top: 8px;
    width: 225px;
    transition: opacity 250ms;
    opacity: 1;

    @media (max-height: 700px) {
      margin-top: 28px;
    }

    ${({$disabled}) => $disabled && css`
      opacity: 0.125;
      pointer-events: none;
    `}
  `,
  PopupContainer: styled.div<DisplayProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    inset: 0;
    z-index: 100;
    background: #00000080;

    opacity: 0;
    animation-duration: .25s;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-name: ${appearKeyframes};

    ${({$hidden, $initHidden}) => $hidden && css`
      ${() => $initHidden && css`animation-duration: 0s;`}
      animation-name: ${disappearKeyframes};
      pointer-events: none;
    `}
  `,
  PopupContent: styled.div`
    background: ${props => props.theme.primaryColor};
    width: 100%;
    max-width: 500px;
    margin: 24px;
    padding: 24px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (max-height: 700px) {
      margin: 16px;
    }
  `,
  InfoContainer: styled.div`
    display: flex;
    justify-content: center;
    align-items: start;
    margin-bottom: 20px;
    width: 100%;

    @media (max-height: 700px) {
      margin-bottom: 16px;
    }
  `,
  InfoLabel: styled.div`
    font-size: 16px;
    flex: .75;
    text-align: right;
    margin-top: 5px;
    position: relative;

    .required {
      position: absolute;
      color: red;
      top: -2px;
      right: -8px;
      font-size: 16px;
    }
  `,
  InfoValue: styled.div`
    flex: 1;
    margin-left: 24px;
    height: 32px;

    @media (max-height: 700px) {
      margin-left: 20px;
    }

    > * {
      box-sizing: border-box;
    }
  `,
  Input: styled.input`
    width: 100%;
    height: 100%;
    border: 1px solid ${props => props.theme.accentColor};
    border-radius: 4px;
    background: ${props => props.theme.primary};
    color: ${props => props.theme.accentColor};
    padding: 4px 8px;
  `,
  Select: styled.select`
    width: 100%;
    height: 100%;
    border: 1px solid ${props => props.theme.accentColor};
    border-radius: 4px;
    background: ${props => props.theme.primary};
    color: ${props => props.theme.accentColor};
    padding: 4px 8px;
  `
}

type TextInputProps = {
  type: 'text' | 'email' | 'phone' | 'number'
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type'>;

function TextInput({type, ...props}: TextInputProps) {
  return <S.Input {...props} type={type}/>;
}

type OccupationInputProps = React.SelectHTMLAttributes<HTMLSelectElement>;

function OccupationInput(props: OccupationInputProps) {
  return (
    <>
      <S.Select {...props}>
        <option hidden value={undefined}>Please select</option>
        <option value="Front-end developer">Front-end developer</option>
        <option value="Back-end developer">Back-end developer</option>
        <option value="Mobile developer">Mobile developer</option>
        <option value="Fullstack developer">Fullstack developer</option>
        <option value="DevOps">DevOps</option>
        <option value="QA">QA</option>
        <option value="BA">BA</option>
        <option value="Project manager">Project manager</option>
        <option value="Student">Student</option>
        <option value="Other">Other..</option>
      </S.Select>
    </>
  )
}

type YearOfExpInputProps = React.SelectHTMLAttributes<HTMLSelectElement>;

function YearOfExpInput(props: YearOfExpInputProps) {
  return (
    <S.Select {...props}>
      <option hidden value={undefined}>Please select</option>
      <option value="1 year">1 year</option>
      <option value="2 years">2 years</option>
      <option value="3 years">3 years</option>
      <option value="4 years">4 years</option>
      <option value="5 years">5 years</option>
      <option value="6-10 years">6-10 years</option>
      <option value="10+ years">10+ years</option>
    </S.Select>
  )
}

type YesNoInputProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type' | 'name' | 'value'>;

function YesNoInput(props: YesNoInputProps) {
  const [radioId] = useState(`${Date.now()}`);
  return (
    <div style={{display: 'flex', justifyContent: 'space-evenly', marginTop: 8}}>
      <div>
        <input {...props} type="radio" name={radioId} value="yes"/> Yes
      </div>
      <div>
        <input {...props} type="radio" name={radioId} value="no"/> No
      </div>
    </div>
  )
}

type CollectInfoPopupProps = DisplayProps & {
  eventId?: string;
  collectInfo?: { id: string, name: string, type: string, isRequired: boolean }[];
  onStart: () => void;
}

function CollectInfoPopup({eventId, collectInfo, onStart, $hidden,}: CollectInfoPopupProps) {
  const [initHidden, initChanged] = useInitPropChanged($hidden);
  const [collectedInfo, setCollectedInfo] = useState(_
    .chain(collectInfo)
    .map(info => ({
      id: info.id,
      name: info.name,
      value: '',
    }))
    .keyBy(info => info.id)
    .value());
  const [submitCollectedInfo] = useMutation(CREATE_COLLECTED_INFO_MUTATION, {});

  const handleOnChange = useCallback((info: { id: string, name: string, value: string }) => {
    collectedInfo[info.id] = info;
    setCollectedInfo({...collectedInfo});
  }, [collectedInfo]);

  const handleStart = useCallback(() => {
    const mappedInfo = _.values(collectedInfo).map(({name: q, value: a}) => ({q, a}));
    submitCollectedInfo({
      variables: {
        data: {
          event: {
            connect: {
              id: eventId
            }
          },
          data: mappedInfo
        }
      }
    })
    onStart();
  }, [collectedInfo, onStart, submitCollectedInfo, eventId]);

  const isValid = useMemo(() => {
    const requiredInputIds = _.chain(collectInfo)
      .filter(info => info.isRequired)
      .map(info => info.id)
      .value();
    return _.every(requiredInputIds, requiredInputId => !!collectedInfo[requiredInputId].value);
  }, [collectInfo, collectedInfo]);

  return (
    <S.PopupContainer $hidden={$hidden} $initHidden={initHidden && !initChanged}>
      <S.PopupContent>
        <S.Title>
          Let us know you more!
        </S.Title>
        {_.map(collectInfo, info => (
          <React.Fragment key={info.id}>
            <S.InfoContainer>
              <S.InfoLabel>{info.name}{info.isRequired ? <span className="required">*</span> : ''}</S.InfoLabel>
              <S.InfoValue>
                {(() => {
                  switch (info.type) {
                    case 'text':
                    case 'email':
                    case 'phone':
                    case 'number':
                      return <TextInput type={info.type} onChange={(event) => handleOnChange({id: info.id, name: info.name, value: event.target.value})}/>
                    case 'occupation':
                      return <OccupationInput onChange={(event) => handleOnChange({id: info.id, name: info.name, value: event.target.value})}/>
                    case 'yoe':
                      return <YearOfExpInput onChange={(event) => handleOnChange({id: info.id, name: info.name, value: event.target.value})}/>
                    case 'yes-no':
                      return <YesNoInput onChange={(event) => handleOnChange({id: info.id, name: info.name, value: event.target.value})}/>
                    default:
                      return (<TextInput type="text" onChange={(event) => handleOnChange({id: info.id, name: info.name, value: event.target.value})}/>)
                  }
                })()}
              </S.InfoValue>
            </S.InfoContainer>
          </React.Fragment>
        ))}
        <S.StartButton $disabled={!isValid} onClick={handleStart}>Start Quiz!</S.StartButton>
      </S.PopupContent>
    </S.PopupContainer>
  );
}

export default CollectInfoPopup;
