import React, {useMemo, useRef} from 'react';
import styled, {css, keyframes} from "styled-components";
import _ from "lodash";
import type {FireworksHandlers} from '@fireworks-js/react'
import {Fireworks} from '@fireworks-js/react'
import FlyingEmoji from "../../components/FlyingEmoji";
import {PageLayoutContent} from '../../components/PageLayout';
import useInitPropChanged from "../../hooks/useInitPropChanged";
import {resolveImageUrl} from "../../utils";
import {DisplayProps} from "./index";
import {Reward} from "./useEventQuiz";
import Panel from "../../components/Panel";
import {FacebookFilled, InstagramFilled, LinkedinFilled} from "@ant-design/icons";

const appearKeyframes = keyframes`
  from {
    opacity: 0;
    filter: blur(16px);
  }
  to {
    opacity: 1;
    filter: blur(0);
  }
`;

const disappearKeyframes = keyframes`
  from {
    opacity: 1;
    filter: blur(0);
  }
  to {
    opacity: 0;
    filter: blur(16px);
  }
`;

const S = {
  Container: styled(PageLayoutContent)<DisplayProps>`
    z-index: 1;
    opacity: 0;
    position: absolute;
    inset: 0;
    animation-duration: .25s;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-name: ${appearKeyframes};
    animation-delay: .25s;

    ${({$hidden, $initHidden}) => $hidden && css`
      ${() => $initHidden && css`animation-duration: 0s;`}
      animation-name: ${disappearKeyframes};
      animation-delay: 0s;
      pointer-events: none;
    `}
  `,
  Content: styled(PageLayoutContent)`
    justify-content: space-evenly;
  `,
  LogoContainer: styled.div``,
  Logo: styled.img`
    width: auto;
    max-width: 500px;
    height: 50px;
    object-fit: contain;
    border-radius: 6px;

    @media (max-height: 700px) {
      height: 35px;
      border-radius: 4px;
    }
  `,
  Title: styled.div`
    font-size: 24px;

    @media (max-height: 700px) {
      font-size: 20px;
    }
  `,
  Score: styled.div`
    display: inline-block;
    font-size: 64px;
    position: relative;

    @media (max-height: 700px) {
      font-size: 52px;
    }
  `,
  ImgPanel: styled(Panel)`
    width: 100%;
    box-sizing: border-box;
  `,
  Img: styled.img`
    width: 100%;
    height: auto;
    max-width: 500px;
    max-height: 250px;
    object-fit: contain;
  `,
  Fireworks: styled(Fireworks)`
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    position: fixed;
    background: transparent;
    pointer-events: none;
    z-index: 2;
  `,
  FlyingEmoji: styled(FlyingEmoji)`
    z-index: 2;
  `,
  FooterContainer: styled.div`
    display: flex;
    flex-direction: row;
    height: 50px;
    align-items: center;
    justify-content: center;
    padding: 0 16px;
    box-sizing: border-box;
    border-radius: 8px;
    background: ${props => props.theme.accentColor}0d;

    a {
      color: inherit;
      text-decoration: underline;

      &:hover {
        color: inherit;
        text-decoration: none;
        cursor: pointer;
      }
    }

    > :nth-child(n+2) {
      margin-left: 12px;
      padding-left: 12px;
      border-left: 1px solid ${props => props.theme.accentColor}1f;
      box-sizing: border-box;
    }
  `,
  FooterIcon: styled.div`
    font-size: 28px;

    @media (max-height: 700px) {
      font-size: 24px;
    }
  `,
  FooterLink: styled.div`
    font-size: 16px;

    @media (max-height: 700px) {
      font-size: 12px;
    }
  `,
}

type FinishedProps = DisplayProps & {
  logoImage?: { url: string },
  rewards?: Reward[],
  facebookUrl?: string,
  instagramUrl?: string,
  linkedinUrl?: string,
  contactUrls?: {id: string, label: string, url: string}[],
  score: number,
  thankYouMessage: string;
  noRewardMessage: string;
}

function Finished({
                    logoImage,
                    facebookUrl,
                    instagramUrl,
                    linkedinUrl,
                    contactUrls,
                    score,
                    rewards,
                    thankYouMessage,
                    noRewardMessage,
                    $hidden
                  }: FinishedProps) {
  const [initHidden, initChanged] = useInitPropChanged($hidden);
  const ref = useRef<FireworksHandlers>(null);

  const reward = useMemo(() => {
    if (rewards) {
      const finalScore = Math.round(score);
      const sortedRewards = _.sortBy(rewards, reward => reward.requiredPoints);
      return _.findLast(sortedRewards, reward => reward.requiredPoints <= finalScore);
    }
  }, [score, rewards]);

  return (
    <>
      <S.Container $hidden={$hidden} $initHidden={initHidden && !initChanged}>
        {logoImage?.url && (
          <S.LogoContainer>
            <S.Logo src={resolveImageUrl(logoImage.url)}/>
          </S.LogoContainer>
        )}
        <S.Content>
          <div>
            <S.Title>Your score</S.Title>
            <S.Score>{Math.round(score)}</S.Score>
          </div>
          {reward && (
            <S.ImgPanel>
              <S.Title>{reward.congratsMessage}</S.Title>
              {reward.image?.url && (<>
                <br/>
                <S.Img src={resolveImageUrl(reward.image?.url)}/>
                <br/>
              </>)}
            </S.ImgPanel>
          )}
          {rewards && !reward && (
            <Panel>
              <S.Title>{noRewardMessage}</S.Title>
            </Panel>
          )}
          <S.Title>{thankYouMessage}</S.Title>
        </S.Content>
        <S.FooterContainer>
          {facebookUrl && (
            <S.FooterIcon>
              <a href={facebookUrl} target="_blank" rel="noreferrer"><FacebookFilled/></a>
            </S.FooterIcon>
          )}
          {instagramUrl && (
            <S.FooterIcon>
              <a href={instagramUrl} target="_blank" rel="noreferrer"><InstagramFilled/></a>
            </S.FooterIcon>
          )}
          {linkedinUrl && (
            <S.FooterIcon>
              <a href={linkedinUrl} target="_blank" rel="noreferrer"><LinkedinFilled/></a>
            </S.FooterIcon>
          )}
          {_.map(contactUrls, contactUrl => (
            <S.FooterLink key={contactUrl.id}>
              <a href={contactUrl.url} target="_blank" rel="noreferrer">{contactUrl.label}</a>
            </S.FooterLink>
          ))}
        </S.FooterContainer>
      </S.Container>
      {!$hidden && (!rewards || reward) && (<S.Fireworks ref={ref} options={{opacity: 1}}/>)}
      {!$hidden && (rewards && !reward) && (<S.FlyingEmoji emoji={'😢'} duration={5}/>)}
    </>
  );
}

export default Finished;
