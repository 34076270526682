import styled from "styled-components";


const Button = styled.button`
  background: none;
  border: 1px solid;
  font-size: 24px;
  padding: 8px 24px;
  border-radius: 4px;
  border-color: ${props => props.theme.accentColor};
  background-color: ${props => props.theme.accentColor};
  color: ${props => props.theme.primaryColor};

  @media (max-height: 700px) {
    font-size: 20px;
  }
`;

export default Button;
