import React, {useCallback, useState} from 'react';
import styled, {css, keyframes} from "styled-components";
import Button from "../../components/Button";
import {resolveImageUrl} from "../../utils";
import {PageLayoutContent, PageLayoutScrollableContent, PageLayoutScrollingContent} from '../../components/PageLayout';
import {DisplayProps} from "./index";
import CollectInfoPopup from "./CollectInfoPopup";

const fadeInDownKeyframes = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;
const fadeInUpKeyframes = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeOutDownKeyframes = keyframes`
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
`;
const fadeOutUpKeyframes = keyframes`
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(20px);
  }
`;

const S = {
  Container: styled(PageLayoutContent)`
    position: absolute;
    inset: 0;
  `,
  LogoContainer: styled.div<DisplayProps>`
    opacity: 0;
    animation-duration: .25s;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-delay: .5s;
    animation-name: ${fadeInDownKeyframes};

    ${({$hidden}) => $hidden && css`
      animation-delay: 0s;
      animation-name: ${fadeOutDownKeyframes};
      pointer-events: none;
    `}
  `,
  Logo: styled.img`
    width: auto;
    max-width: 500px;
    height: 75px;
    border-radius: 6px;
    object-fit: contain;

    @media (max-height: 700px) {
      height: 35px;
      border-radius: 4px;
    }
  `,
  WelcomeContainer: styled(PageLayoutScrollableContent)<DisplayProps>`
    opacity: 0;
    animation-duration: .25s;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-delay: .5s;
    flex: 1;
    animation-name: ${fadeInDownKeyframes};

    ${PageLayoutScrollingContent} {
      display: flex;
      flex-direction: column;
      gap: 24px;
      justify-content: space-evenly;

      @media (max-height: 700px) {
        gap: 16px;
      }
    }

    ${({$hidden}) => $hidden && css`
      animation-delay: 0s;
      animation-name: ${fadeOutDownKeyframes};
      pointer-events: none;
    `}
  `,
  Title: styled.div`
    font-size: 32px;

    @media (max-height: 700px) {
      font-size: 28px;
    }
  `,
  Img: styled.img`
    width: 100%;
    height: auto;
    object-fit: contain;
    max-width: 500px;
    overflow: hidden;
    max-height: 100%;
  `,
  StartContainer: styled.div<DisplayProps>`
    opacity: 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 24px;
    animation-duration: .25s;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-delay: .5s;
    animation-name: ${fadeInUpKeyframes};

    ${({$hidden}) => $hidden && css`
      animation-delay: 0s;
      animation-name: ${fadeOutUpKeyframes};
      pointer-events: none;
    `}
  `,
  StartButton: styled(Button)``,
}

type IdleProps = DisplayProps & {
  eventId?: string,
  welcomeMessage?: string,
  logoImage?: { url: string };
  image?: { url: string };
  collectInfo?: {id: string, name: string, type: string, isRequired: boolean}[];
  start: () => void;
}

function Idle({eventId, welcomeMessage, logoImage, image, collectInfo, start, $hidden,}: IdleProps) {
  const [collectInfoPopupHidden, setCollectInfoPopupHidden] = useState(true);
  const handleNext = useCallback(() => {
    setCollectInfoPopupHidden(false);
  }, []);
  const handleStart = useCallback(() => {
    setCollectInfoPopupHidden(true);
    start();
  }, [start]);

  if (!welcomeMessage) {
    return <></>;
  }

  return (
    <S.Container>
      <S.LogoContainer $hidden={$hidden}>
        {!!logoImage?.url && (<S.Logo src={resolveImageUrl(logoImage.url)}/>)}
      </S.LogoContainer>
      <S.WelcomeContainer $hidden={$hidden}>
        <S.Title>{welcomeMessage}</S.Title>
        {image?.url && (<>
          <S.Img src={resolveImageUrl(image?.url)}/>
        </>)
        }
      </S.WelcomeContainer>
      <S.StartContainer $hidden={$hidden}>
        <S.Title>Are you ready?</S.Title>
        {collectInfo && collectInfo.length > 0 && (
          <S.StartButton onClick={handleNext}>Next</S.StartButton>
        )}
        {(!collectInfo || collectInfo.length <= 0) && (
          <S.StartButton onClick={handleStart}>Start</S.StartButton>
        )}
      </S.StartContainer>
      <CollectInfoPopup eventId={eventId} collectInfo={collectInfo} onStart={handleStart} $hidden={collectInfoPopupHidden} />
    </S.Container>
  );
}

export default Idle;
