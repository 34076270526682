import React, {useEffect, useMemo, useState} from 'react';
import {useLoaderData} from "react-router-dom";
import styled from "styled-components";
import {gql, useLazyQuery} from "@apollo/client";
import _ from 'lodash';
import {preloadImages, resolveImageUrl} from "../../utils";
import {EventQuiz, useEventQuiz} from "./useEventQuiz";
import Playing from "./Playing";
import Starting from "./Starting";
import Idle from "./Idle";
import LuckyStarConfirm from "./LuckyStarConfirm";
import Finished from "./Finished";
import useApp from "../../hooks/useApp";

const S = {
  Title: styled.h1`
    margin: 0;
    font-size: 40px;
  `,
}

const EVENT_QUERY = gql`
    query Event($where: EventWhereUniqueInput!) {
        event(where: $where) {
            id
            name
            welcomeMessage
            profile {
                logoImage {
                    url
                }
                primaryColor
                accentColor
                facebookUrl
                instagramUrl
                linkedinUrl
                contactUrlsJson
            }
            collectInfoJson
            image {
                url
            }
            quizSet {
                quizzes {
                    id
                    question
                    image {
                        url
                    }
                    correctAnswer
                    wrongAnswer
                    timeout
                }
                luckyStarQuizzes {
                    id
                    question
                    image {
                        url
                    }
                    correctAnswer
                    wrongAnswer
                    timeout
                }
            }
            numberOfQuizzes
            numberOfLuckyStarQuizzes
            rewards {
                id
                name
                congratsMessage
                requiredPoints
                image {
                    url
                }
            }
            noRewardMessage
            thankYouMessage
        }
    }
`;

export type DisplayProps = {
  $hidden?: boolean;
  $initHidden?: boolean;
}

type EventLoaderProps = {
  eventId: string,
}

function Event() {
  const {setTheme, setLoading, setLoadingText} = useApp();
  const {eventId} = useLoaderData() as EventLoaderProps;
  const [isPrepared, setPrepared] = useState(false);
  const [fetchEvent, {data, loading, error}] = useLazyQuery<{ event: EventQuiz }>(EVENT_QUERY);
  const event = useMemo(() => data?.event, [data?.event]);
  const {
    setEvent,
    state: eventQuizState,
    start,
    quiz,
    quizzes,
    luckyStarQuizzes,
    startingCountdown,
    progress,
    answer,
    acceptLuckyStar,
    nextQuizDelay,
  } = useEventQuiz();

  useEffect(() => {
    (async () => {
      await fetchEvent({
        variables: {
          where: {
            id: eventId
          },
        }
      });
    })();
  }, [fetchEvent, eventId]);

  useEffect(() => {
    setEvent(event);
    setTheme({primaryColor: event?.profile?.primaryColor, accentColor: event?.profile?.accentColor});
  }, [event, setEvent, setTheme]);

  useEffect(() => {
    if (!isPrepared && event === null) {
      setPrepared(true);
    }
    if (!isPrepared && event && quizzes && luckyStarQuizzes) {
      (async () => {
        const quizImgUrls = _.map([...quizzes, ...luckyStarQuizzes], ({image}): string => resolveImageUrl(image?.url));
        const rewardImgUrls = _.map(event.rewards, ({image}): string => resolveImageUrl(image?.url));
        await preloadImages([resolveImageUrl(event.image?.url), ...quizImgUrls, ...rewardImgUrls], (progress) => {
          setLoadingText(`${Math.round(progress * 100)}%`);
        });
        setPrepared(true);
      })()
    }
  }, [isPrepared, event, quizzes, luckyStarQuizzes, setLoadingText]);

  useEffect(() => {
    setLoading(loading || !isPrepared);
  }, [loading, isPrepared, setLoading]);

  const collectInfo: {id: string, name: string, type: string, isRequired: boolean}[] = useMemo(() => {
    if (!event?.collectInfoJson) return [];
    try {
      return JSON.parse(event?.collectInfoJson) || [];
    } catch (err) {
      return [];
    }
  }, [event?.collectInfoJson]);

  const contactUrls: {id: string, label: string, url: string}[] = useMemo(() => {
    if (!event?.profile?.contactUrlsJson) return [];
    try {
      return JSON.parse(event?.profile?.contactUrlsJson) || [];
    } catch (err) {
      return [];
    }
  }, [event?.profile?.contactUrlsJson]);

  if (error) {
    return (
      <S.Title>An error has occurred. Please try again 😢.</S.Title>
    );
  }

  if (loading || !isPrepared) {
    return <></>
  }

  if (!event || eventQuizState === 'NO_EVENT') {
    return (
      <S.Title>404 - Event not found 🤔</S.Title>
    );
  }

  return (
    <>
      <Idle eventId={event.id} logoImage={event.profile?.logoImage} welcomeMessage={event.welcomeMessage} image={event.image} start={start}
            collectInfo={collectInfo} $hidden={eventQuizState !== 'IDLE'}/>
      <Starting eventName={event.name} startingCountdown={startingCountdown} $hidden={eventQuizState !== 'STARTING'}/>
      <Playing quiz={quiz} answer={answer} progress={progress} $hidden={eventQuizState !== 'PLAYING'}
               nextQuizDelay={nextQuizDelay}/>
      <LuckyStarConfirm score={progress.score} numberOfLuckyStarQuizzes={event.numberOfLuckyStarQuizzes}
                        acceptLuckyStar={acceptLuckyStar} $hidden={eventQuizState !== 'LUCKY_STAR_CONFIRMING'}/>
      <Finished logoImage={event.profile?.logoImage} score={progress.score} thankYouMessage={event.thankYouMessage}
                noRewardMessage={event.noRewardMessage} rewards={event.rewards}
                facebookUrl={event.profile?.facebookUrl} instagramUrl={event.profile?.instagramUrl}
                linkedinUrl={event.profile?.linkedinUrl} contactUrls={contactUrls}
                $hidden={eventQuizState !== 'FINISHED'}/>
    </>
  );
}

export default Event;
