import React from 'react';
import styled, {css, keyframes} from "styled-components";
import Button from "../../components/Button";
import {PageLayoutContent, PageLayoutScrollableContent, PageLayoutScrollingContent} from "../../components/PageLayout";
import useInitPropChanged from "../../hooks/useInitPropChanged";
import {DisplayProps} from "./index";
import Panel from "../../components/Panel";

const appearKeyframes = keyframes`
  from { opacity: 0; filter: blur(16px); }
  to { opacity: 1; filter: blur(0); }
`;

const disappearKeyframes = keyframes`
  from { opacity: 1; filter: blur(0); }
  to { opacity: 0; filter: blur(16px); }
`;

const S = {
  Container: styled(PageLayoutContent)<DisplayProps>`
    position: absolute;
    opacity: 0;
    inset: 0;
    animation-duration: .25s;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-name: ${appearKeyframes};
    animation-delay: .25s;

    ${({$hidden, $initHidden}) => $hidden && css`
      ${() => $initHidden && css`animation-duration: 0s;`}
      animation-name: ${disappearKeyframes};
      animation-delay: 0s;
      pointer-events: none;
    `}
  `,
  Content: styled(PageLayoutScrollableContent)`
    ${PageLayoutScrollingContent} {
      padding: 0;
    }
  `,
  Title: styled.div`
    font-size: 24px;

    @media (max-height: 700px) {
      font-size: 20px;
    }
  `,
  Score: styled.div`
    display: inline-block;
    font-size: 64px;
    position: relative;

    @media (max-height: 700px) {
      font-size: 52px;
    }
  `,
  AnswerContainer: styled.div`
    display: flex;
    gap: 24px;
    flex-direction: row;
    margin: 24px 0;
    width: 100%;
  `,
  AnswerButton: styled(Button)`
    flex: 1;
  `,
}

type LuckyStarProps = DisplayProps & {
  score: number;
  numberOfLuckyStarQuizzes: number;
  acceptLuckyStar: (ok: boolean) => void;
}

function LuckyStarConfirm({score, numberOfLuckyStarQuizzes, acceptLuckyStar, $hidden}: LuckyStarProps) {
  const [initHidden, initChanged] = useInitPropChanged($hidden);

  return (
    <S.Container $hidden={$hidden} $initHidden={initHidden && !initChanged}>
      <S.Content>
        <S.Title>Current score</S.Title>
        <S.Score>{Math.round(score)}</S.Score>
        <br/>
        <br/>
        <Panel>
          <S.Title>Double or nothing by answering {numberOfLuckyStarQuizzes} more
            quiz{numberOfLuckyStarQuizzes > 1 && 'zes'}?</S.Title>
        </Panel>
      </S.Content>
      <S.AnswerContainer>
        <S.AnswerButton onClick={() => acceptLuckyStar(true)}>Yes 💪</S.AnswerButton>
        <S.AnswerButton onClick={() => acceptLuckyStar(false)}>No 🙈</S.AnswerButton>
      </S.AnswerContainer>
    </S.Container>
  );
}

export default LuckyStarConfirm;
