import styled, {css, keyframes} from "styled-components";
import LoadingIcon from "./LoadingIcon";
import PageLayout, {PageLayoutContent} from "./PageLayout";

type LoadingScreenProps = {
  loading: boolean;
  loadingText?: string;
}

const appearKeyframes = keyframes`
  from { opacity: 0; filter: blur(16px); }
  to { opacity: 1; filter: blur(0); }
`;

const disappearKeyframes = keyframes`
  from { opacity: 1; filter: blur(0); }
  to { opacity: 0; filter: blur(16px); }
`;

const S = {
  PageLayout: styled(PageLayout)<LoadingScreenProps>`
    pointer-events: none;
    animation: .25s ${disappearKeyframes} linear forwards;

  	${({loading}) => loading && css`
      pointer-events: auto;
      animation: .25s ${appearKeyframes} linear forwards;
    `}
    
    ${PageLayoutContent} {
      gap: 8px;
    }
    
    .loading-text {
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `,
}
const LoadingScreen = ({loading, loadingText}: LoadingScreenProps) => {
  return (
    <S.PageLayout loading={loading}>
      <LoadingIcon/>
      <div className="loading-text">{loadingText}</div>
    </S.PageLayout>
  )
}

export default LoadingScreen;
