import React, {useEffect, useState} from 'react';

const resolver = {
  resolve: function resolve(options: any, callback: Function) {
    // The string to resolve
    const resolveString = options.resolveString || options.element.getAttribute('data-target-resolver');
    const combinedOptions = Object.assign({}, options, {resolveString: resolveString});

    function getRandomInteger(min: number, max: number) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    };

    function randomCharacter(characters: string) {
      return characters[getRandomInteger(0, characters.length - 1)];
    };

    function doRandomiserEffect(options: any, callback: Function) {
      const characters = options.characters;
      const onTextChange = options.onTextChange;
      const partialString = options.partialString;

      let iterations = options.iterations;

      setTimeout(() => {
        if (iterations >= 0) {
          const nextOptions = Object.assign({}, options, {iterations: iterations - 1});

          // Ensures partialString without the random character as the final state.
          if (iterations === 0) {
            onTextChange(partialString);
          } else {
            // Replaces the last character of partialString with a random character
            onTextChange(partialString.substring(0, partialString.length - 1) + randomCharacter(characters));
          }

          doRandomiserEffect(nextOptions, callback)
        } else if (typeof callback === "function") {
          callback();
        }
      }, options.timeout);
    };

    function doResolverEffect(options: any, callback: Function) {
      const resolveString = options.resolveString;
      const offset = options.offset;
      const partialString = resolveString.substring(0, offset);
      const combinedOptions = Object.assign({}, options, {partialString: partialString});

      doRandomiserEffect(combinedOptions, () => {
        const nextOptions = Object.assign({}, options, {offset: offset + 1});

        if (offset <= resolveString.length) {
          doResolverEffect(nextOptions, callback);
        } else if (typeof callback === "function") {
          callback();
        }
      });
    };

    doResolverEffect(combinedOptions, callback);
  }
}

type TypingTextAnimationProps = {
  strings: string[],
  replay?: boolean,
  replayTimeout?: number
  characters?: string[],
  offset?: number,
  timeout?: number,
  iterations?: number,
};
function TypingTextAnimation({strings, replay, replayTimeout, offset, timeout, iterations, characters}: TypingTextAnimationProps) {
  const [text, setText] = useState('');

  useEffect(() => {
    let counter = 0;

    const options = {
      // Initial position
      offset: offset || 0,
      // Timeout between each random character
      timeout: timeout || 50,
      // Number of random characters to show
      iterations: iterations || 10,
      // Random characters to pick from
      characters: characters || ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'x', 'y', 'x', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'X', 'Y', 'X', '#', '%', '&', '-', '+', '_', '?', '/', '\\', '='],
      // String to resolve
      resolveString: strings[counter],
      onTextChange: setText,
    }

    // Callback function when resolve completes
    function callback() {
      setTimeout(() => {
        counter++;

        if (counter >= strings.length) {
          counter = 0;

          if (!replay) {
            return;
          }
        }

        let nextOptions = Object.assign({}, options, {resolveString: strings[counter]});
        resolver.resolve(nextOptions, callback);
      }, replayTimeout || 2000);
    }

    resolver.resolve(options, callback);
  }, [characters, iterations, offset, replay, replayTimeout, strings, timeout]);

  return (
    <span>{text}</span>
  );
}

export default TypingTextAnimation;
