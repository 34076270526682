import React, {ReactNode} from 'react';
import ReactDOM from 'react-dom/client';
import {ApolloClient, ApolloProvider, InMemoryCache} from "@apollo/client";
import {ThemeProvider} from "styled-components";
import {Helmet} from "react-helmet";
import './index.css';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import Home from "./pages/Home";
import Event from "./pages/Event";
import useApp, {AppProvider} from "./hooks/useApp";
import PageLayout from "./components/PageLayout";
import LoadingScreen from "./components/LoadingScreen";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home/>,
  },
  {
    path: "/event/:eventId",
    element: <Event/>,
    loader: ({params}) => ({eventId: params.eventId})
  },
]);

const apolloClient = new ApolloClient({
  uri: process.env.REACT_APP_ANEHTA_CMS_API_URL,
  cache: new InMemoryCache()
});

const ThemeApp = ({children}: { children: ReactNode }) => {
  const {theme, loading, loadingText} = useApp();

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <meta name="msapplication-TileColor" content={theme.primaryColor}/>
        <meta name="theme-color" content={theme.primaryColor} />
      </Helmet>
      {children}
      <LoadingScreen loading={loading} loadingText={loadingText}/>
    </ThemeProvider>
  )
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ApolloProvider client={apolloClient}>
      <AppProvider>
        <ThemeApp>
          <PageLayout>
          	<RouterProvider router={router}/>
          </PageLayout>
        </ThemeApp>
      </AppProvider>
    </ApolloProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
