import React, {createContext, ReactNode, useCallback, useContext, useEffect, useState} from 'react';
import ReactGA from "react-ga4";
import {GA4} from "react-ga4/types/ga4";
import {AnehtaTheme} from "../ @types/styled";

interface AppState {
  theme: AnehtaTheme;
  setTheme: (theme: Partial<AnehtaTheme>) => void;
  resetTheme: () => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  loadingText?: string,
  setLoadingText: (text: string) => void,
  ga: GA4,
}

const DEFAULT_THEME: AnehtaTheme = Object.freeze({
  primaryColor: "#66ddff",
  accentColor: "#ffffff",
  correctColor: "#61e752",
  wrongColor: "#e75252",
})

const AppContext = createContext<AppState>({
  theme: DEFAULT_THEME,
  setTheme: () => {},
  resetTheme: () => {},
  loading: false,
  setLoading: () => {},
  loadingText: undefined,
  setLoadingText: () => {},
  ga: ReactGA,
});

export function AppProvider({children}: { children: ReactNode }) {
  const [anehtaTheme, setAnehtaTheme] = useState<AnehtaTheme>(DEFAULT_THEME);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingText, setLoadingText] = useState<string>();

  useEffect(() => {
    const REACT_APP_GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID;
    if (REACT_APP_GA_MEASUREMENT_ID) {
      ReactGA.initialize(REACT_APP_GA_MEASUREMENT_ID);
    }
  }, []);

  const setTheme = useCallback((theme: Partial<AnehtaTheme>) => {
    setAnehtaTheme({
      primaryColor: theme?.primaryColor || anehtaTheme.primaryColor,
      accentColor: theme?.accentColor || anehtaTheme.accentColor,
      correctColor: theme?.correctColor || anehtaTheme.correctColor,
      wrongColor: theme?.wrongColor || anehtaTheme.wrongColor,
    })
  }, [anehtaTheme.primaryColor, anehtaTheme.accentColor, anehtaTheme.correctColor, anehtaTheme.wrongColor]);

  const resetTheme = useCallback(() => {setAnehtaTheme(DEFAULT_THEME)}, [])

  return (
    <AppContext.Provider
      value={{theme: anehtaTheme, setTheme, resetTheme, loading, setLoading, loadingText, setLoadingText, ga: ReactGA}}>
      {children}
    </AppContext.Provider>
  );
}

export default function useApp() {
  const {...props} = useContext(AppContext);

  return {...props};
}
