import React, {useCallback, useEffect, useMemo, useState} from 'react';
import styled, {css, keyframes} from "styled-components";
import {ClockCircleOutlined, ThunderboltOutlined} from "@ant-design/icons";
import _ from "lodash";
import Button from "../../components/Button";
import FlyingEmoji, {Emoji} from "../../components/FlyingEmoji";
import {PageLayoutContent, PageLayoutScrollableContent} from "../../components/PageLayout";
import useApp from "../../hooks/useApp";
import useInitPropChanged from "../../hooks/useInitPropChanged";
import {resolveImageUrl} from "../../utils";
import {EventQuizProgress, Quiz as QuizType} from './useEventQuiz';
import {DisplayProps} from "./index";

const collapseKeyframes = keyframes`
  from {
    transform: scaleX(1);
  }
  to {
    transform: scaleX(0);
  }
`;

const expandKeyframes = keyframes`
  from {
    transform: scaleX(0);
  }
  to {
    transform: scaleX(1);
  }
`;

const pushingKeyframes = keyframes`
  0% {
    opacity: 1;
    transform: scale(.95);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

const shakingKeyframes = keyframes`
  0% {
    opacity: 1;
    transform: skewY(-5deg);
  }
  25% {
    transform: skewY(5deg);
  }
  50% {
    transform: skewY(-5deg);
  }
  750% {
    transform: skewY(5deg);
  }
  100% {
    opacity: 1;
    transform: skewY(0deg);
  }
`;

const flyingUpKeyframes = keyframes`
  0% {
    opacity: 1;
    top: 0;
  }
  100% {
    opacity: 0;
    top: -20px;
  }
`;

const appearKeyframes = keyframes`
  from {
    opacity: 0;
    filter: blur(16px);
  }
  to {
    opacity: 1;
    filter: blur(0);
  }
`;

const disappearKeyframes = keyframes`
  from {
    opacity: 1;
    filter: blur(0);
  }
  to {
    opacity: 0;
    filter: blur(16px);
  }
`;

const slideInKeyframes = keyframes`
  0% {
    opacity: 0;
    transform: translateX(20px);
    filter: blur(16px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
    filter: blur(0);
  }
`;

const S = {
  Container: styled(PageLayoutContent)<DisplayProps>`
    z-index: 1;
    opacity: 0;
    position: absolute;
    inset: 0;
    animation-duration: .25s;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-name: ${appearKeyframes};
    animation-delay: .25s;

    ${({$hidden, $initHidden}) => $hidden && css`
      ${() => $initHidden && css`animation-duration: 0s;`}
      animation-name: ${disappearKeyframes};
      animation-delay: 0s;
      pointer-events: none;
    `}
  `,
  StatusContainer: styled.div`
    display: flex;
    width: 100%;
    gap: 16px;
    justify-content: center;
    align-items: center;
    margin-bottom: 3px;
  `,
  StatusTimeIcon: styled.div`
    width: 50px
  `,
  StatusNextQuizTimeout: styled.div<{ $duration?: number, $delay?: number }>`
    top: 0;
    left: 0;
    width: 100vw;
    height: 2px;
    position: fixed;
    z-index: 100;
    transform-origin: 0 100%;
    transform: scaleX(0);
    background: ${props => props.theme.accentColor};

    ${({$duration, $delay}) => _.isNumber($duration) && css`
      animation-name: ${expandKeyframes};
      animation-duration: ${$duration}ms;
      animation-delay: ${$delay}ms;
      animation-timing-function: linear;
    `}
  `,
  StatusTimeout: styled.div<{ $duration: number, $delay: number, $stoppedProgress?: number }>`
    flex: 1;
    height: 100%;
    animation-name: ${collapseKeyframes};
    animation-duration: ${({$duration, $delay}) => $duration - $delay}ms;
    animation-delay: ${({$delay}) => $delay}ms;
    animation-timing-function: linear;
    transform-origin: 0 100%;
    background: ${props => props.theme.accentColor};

    ${({$stoppedProgress}) => _.isNumber($stoppedProgress) && css`
      animation-duration: 0ms;
      transform: scaleX(${$stoppedProgress});
    `}
  `,
  StreakContainer: styled.div`
    flex: 1;
    height: 100%;
    display: flex;
    gap: 3px;
  `,
  StreakPoint: styled.div<{ $streakNumber: number, $streak: number }>`
    flex: 1;
    box-sizing: border-box;
    transition: background-color 250ms;
    background-color: ${props => props.theme.primaryColor};
    height: 5px;
    ${({$streakNumber, $streak}) =>
      $streakNumber <= $streak ?
        css`background-color: ${props => props.theme.accentColor};`
        : css`border: solid 1px ${props => props.theme.accentColor};`
    }
    
  `,
  StreakStatus: styled.div<{ $streak: number, $maxStreak: number }>`
    flex: 1;
    height: 100%;
    transform-origin: 0 100%;
    transform: scaleX(${({$streak, $maxStreak}) => (($streak / $maxStreak) || 0)});
    transition: transform 250ms;
    background: ${props => props.theme.accentColor};
  `,
  StatusProgress: styled.div`
    width: 50px;
  `,
  ScoreContainer: styled.div`
    padding: 32px;
    display: block;
    text-align: center;
    overflow: visible;
    position: relative;
    width: 50%;

    @media (max-height: 700px) {
      padding: 16px;
    }
  `,
  Score: styled.div`
    display: inline-block;
    font-size: 64px;
    position: relative;
    z-index: 1;

    @media (max-height: 700px) {
      font-size: 52px;
    }
  `,
  GainedScore: styled.div`
    position: absolute;
    top: 0;
    left: 100%;
    font-size: 20px;
    animation-duration: .5s;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-iteration-count: 1;
    animation-name: ${flyingUpKeyframes};
  `,
  ScrollableContent: styled(PageLayoutScrollableContent)`
    background: ${props => props.theme.accentColor};
    color: ${props => props.theme.primaryColor};
    border-radius: 16px;
  `,
  QuestionContainer: styled.div<{ $delay: number }>`
    opacity: 0;
    animation: ${slideInKeyframes} .25s linear ${({$delay}) => $delay}ms forwards;
  `,
  Question: styled.div`
    font-size: 24px;

    @media (max-height: 700px) {
      font-size: 20px;
    }
  `,
  Img: styled.img`
    width: 100%;
  `,
  AnswerContainer: styled.div`
    display: flex;
    gap: 12px;
    flex-direction: column;
    margin: 64px 0 0;
    width: 100%;

    @media (max-height: 700px) {
      margin: 32px 0 0;
    }
  `,
  AnswerButton: styled(Button)<{ $correct?: boolean, $delay: number }>`
    flex: 1;

    div {
      animation: ${slideInKeyframes} .25s linear ${({$delay}) => $delay}ms forwards;
    }

    ${({$correct: correct, theme}) => _.isBoolean(correct) && css`
      ${correct && css`
        border-color: ${theme.correctColor};
        background-color: ${theme.correctColor}BF;
        animation-name: ${pushingKeyframes};
      `}
      ${!correct && css`
        border-color: ${theme.wrongColor};
        background-color: ${theme.wrongColor}BF;
        animation-name: ${shakingKeyframes};
      `}
      animation-fill-mode: forwards;
      animation-iteration-count: 1;
      animation-duration: 250ms;
      animation-delay: 0ms;
      animation-timing-function: linear;
      color: #ffffff;
    `}
  `,
  FlyingEmoji: styled(FlyingEmoji)`
    z-index: 0;
    
    ${Emoji} {
      font-size: 24px;

      @media (max-height: 700px) {
        font-size: 16px;
      }
    }
  `
}

type QuizProps = DisplayProps & {
  quiz?: QuizType,
  answer: (answerOption: boolean) => void,
  progress?: EventQuizProgress,
  nextQuizDelay: number,
}

function Playing({quiz, answer, progress, $hidden, nextQuizDelay}: QuizProps) {
  const [initHidden, initChanged] = useInitPropChanged($hidden);
  const [score, setScore] = useState(progress?.score || 0);
  const [answered, setAnswered] = useState<boolean>(false);
  const [answeredOption, setAnsweredOption] = useState<boolean>();
  const [quizStartTimestamp, setQuizStartTimestamp] = useState<number>();
  const [gainedScore, setGainedScore] = useState<{ uid: number, score: number }>();
  const {theme} = useApp();

  useEffect(() => {
    setScore(Math.round(progress?.score || 0));
    if (progress?.score !== undefined) {
      const gainedScore = Math.round(progress?.score) - score;
      gainedScore && setGainedScore({
        uid: Date.now(),
        score: gainedScore,
      });
    }
  }, [progress?.score, score]);

  useEffect(() => {
    quiz && setQuizStartTimestamp(Date.now());
    setAnswered(false);
  }, [quiz]);

  const answerQuiz = useCallback((val: boolean) => {
    answer(val);
    setAnsweredOption(val);
    setAnswered(true);
  }, [answer]);

  const answers = useMemo(() => {
    return _.shuffle([{q: quiz?.correctAnswer, val: true}, {q: quiz?.wrongAnswer, val: false}]);
  }, [quiz]);

  const stoppedProgress = useMemo(() => {
    if (answered && quizStartTimestamp && quiz?.timeout) {
      return 1 - ((Date.now() - quizStartTimestamp) / (quiz?.timeout + (quiz?.extraTime || 0)));
    }
    return undefined;
  }, [answered, quizStartTimestamp, quiz]);

  if (!quiz || !progress) {
    return <></>;
  }

  return (
    <>
      <S.StatusNextQuizTimeout key={`${quiz.uiId}-${answered}`} $duration={nextQuizDelay}
                               $delay={answered ? 0 : quiz?.timeout + (quiz?.extraTime || 0)}/>
      <S.Container $hidden={$hidden} $initHidden={initHidden && !initChanged}>
        <S.StatusContainer>
        <S.StatusTimeIcon>
            <ClockCircleOutlined style={{color: theme.accentColor}}/>
          </S.StatusTimeIcon>
          <S.StatusTimeout key={quiz.uiId} $duration={quiz.timeout} $delay={quiz.extraTime || 0}
                           $stoppedProgress={stoppedProgress}/>
          <S.StatusProgress>{progress.current}/{progress.total}</S.StatusProgress>
        </S.StatusContainer>
        <S.ScoreContainer>
        <S.Score>
            {score}
            {gainedScore?.score && (
              <S.GainedScore
                key={gainedScore?.uid}>{gainedScore?.score > 0 ? `+${gainedScore?.score}` : gainedScore?.score}</S.GainedScore>
            )}
          </S.Score>
          <S.StatusContainer>
            <S.StatusTimeIcon>
              <ThunderboltOutlined style={{color: theme.accentColor}}/>
            </S.StatusTimeIcon>
            <S.StreakContainer key={quiz.uiId}>
              {_.times(progress.maxStreak, (num) =>
                <S.StreakPoint $streakNumber={num + 1} $streak={progress.streak} />
              )}
            </S.StreakContainer>
            <S.StatusTimeIcon>
              <ThunderboltOutlined style={{color: theme.accentColor}}/>
            </S.StatusTimeIcon>
          </S.StatusContainer>
          {!$hidden && progress.isLuckyStar && (<S.FlyingEmoji emoji={'⭐️'}/>)}
        </S.ScoreContainer>
        <S.ScrollableContent>
          <S.QuestionContainer key={quiz.uiId} $delay={quiz.extraTime || 0}>
            <S.Question>{quiz.question}</S.Question>
            {quiz.image && (<>
              <br/>
              <S.Img src={resolveImageUrl(quiz.image.url)}/>
              <br/>
            </>)}
          </S.QuestionContainer>
        </S.ScrollableContent>
        <S.AnswerContainer>
          {_.map(answers, ({q, val}) => (
            <S.AnswerButton key={`${quiz.uiId}-${val}`}
                            onClick={() => !answered && answerQuiz(val)}
                            $correct={answered && answeredOption === val ? answeredOption : undefined}
                            $delay={quiz.extraTime || 0}
            >
              <div>{q}</div>
            </S.AnswerButton>
          ))}
        </S.AnswerContainer>
      </S.Container>
    </>
  );
}

export default Playing;
